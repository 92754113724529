import React, { useCallback, useContext, useEffect, useState } from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone} from "@fortawesome/pro-regular-svg-icons";
import {getCookie} from "cookies-next";

import * as Sentry from "@sentry/nextjs";
import Product from "../../pages/products/Product";
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';
import { LangContext } from '../../../../src/ConnectedIntlProvider';
export default function DialogDuplicate ({duplicateProductID,triggerWarning,
                                             duplicateProductVersions,
                                             reloadProducts,
                                             toggleDuplicate,
                                             currentProductName}) {

    const [duplicatePhase, setDuplicatePhase] = useState(1);

    const [newProductName, setNewProductName] = useState("");

    const { language, setLanguage } = useContext(LangContext);

    const duplicateProduct = () => {

        setDuplicatePhase(2);
    };


    const [newDuplicateUUIDLink, setNewDuplicateUUIDLink] = useState(null);
    const [canDuplicate, setCanDuplicate] = useState(true);


    const duplicateProductAPI = () => {

        if(canDuplicate){
            setCanDuplicate(false);
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+duplicateProductID+"?name="+newProductName+"&product_version="+versionProductShrinkUUID, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("duplicateProduct products/"+duplicateProductID+": "+ data.message + " : " + getCookie("userMail"));
                    }
                    setCanDuplicate(true);
                    if(data.error_code === "SUBSCRIPTION_UPGRADE_REQUIRED"){
                        if(language === "cs"){
                            triggerWarning("Dosáhli jste maximální počet produktů v tomto plánu, pro navýšení limitu prosím proveďte upgrade");
                        } else {
                            triggerWarning("You reach a maximum limit for this plan, for more products please do an upgrade");
                        }

                    }
                    if(data.message === undefined){
                        // changeDuplicateShow(false);
                        reloadProducts();

                        setNewDuplicateUUIDLink(data.data.version_uuid);
                        setDuplicatePhase(3);

                        //    setVersionIsLocked(data.data.locked.locked);
                        // window.open("/editor/"+data.data.uuid, "_self")

                    }
                });
        }

    };


    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [showDuplicateDialogTransition, setShowDuplicateDialogTransition] = useState(false);
    const changeDuplicateShow = (variable) => {
        if(variable === true){
            setDuplicatePhase(1);
            setShowDuplicateDialog(true);
            setTimeout(function() {
                setShowDuplicateDialogTransition(variable);
            },2);

        } else{
            setShowDuplicateDialogTransition(false);
            setTimeout(function() {
                setShowDuplicateDialog(variable);
            },500);

        }
    };

        const toggleDuplicateInside = useCallback((): void => {
            if(showDuplicateDialog){
                changeDuplicateShow(false);
            }else{
                changeDuplicateShow(true);
            }


        }, []);
        useEffect(() => {

            toggleDuplicate && toggleDuplicate(toggleDuplicateInside);
        }, [toggleDuplicate, toggleDuplicateInside]);

    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDuplicateShow(false);
        }
    }

    const [productsInsideShrinkAvailableInnerVersion, setProductsInsideShrinkAvailableInnerVersion] = useState([]);
    const [productsInsideShrinkAvailableInner, setProductsInsideShrinkAvailableInner] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const loadProductVersionsInside = () => {

        setProductsInsideShrinkAvailableInnerVersion(duplicateProductVersions);
    };

    const [versionProductShrinkUUID, setVersionProductShrinkUUID] = useState(null);
    const [windowEditorTypeInner, setWindowEditorTypeInner] = useState("");
    const onClickChooseShrinkFunction = (productUUID) => {
        setVersionProductShrinkUUID(productUUID);


        if(windowEditorTypeInner === "chooseInsideShrinkVersion"){

            setVersionProductShrinkUUID(productUUID);

        } else {

            Object.keys(productsInsideShrinkAvailableInner).map(key => {
                if(productsInsideShrinkAvailableInner[key].uuid === productUUID){
                    setProductsInsideShrinkAvailableInnerVersion(productsInsideShrinkAvailableInner[key].versions);

                }
            });
        }

    }

    useEffect(() => {
        if(duplicateProductVersions !== null  && duplicateProductVersions.length>0){
            loadProductVersionsInside();
        }

    }, [duplicateProductVersions]);



    const handleNewName = function (e: React.FormEvent<HTMLInputElement>) {
        setNewProductName(e.currentTarget.value);
    }

    useEffect(() => {
        if(currentProductName !== null  && currentProductName.length>0){
            setNewProductName(currentProductName + " (Copy)");
        }

    }, [currentProductName]);




    return (
        <>
            <ModalsWrapper className={`  ${showDuplicateDialogTransition ? 'showTransition' : 'hideTransition'} ${!showDuplicateDialog ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow id={'duplicateDialog'} className={"center modal_dialog_big"} style={{background:"white"}}>
                    <div style={{background:"rgba(0,0,0,0.05)", height: "fit-content", display: "contents", position:"absolute",left:"0", right: "0", width:"100%"}} >
                    <div className={'modal-header'}>
                        <h4> <FormattedMessage {...messages.products_modal_duplicate_title}></FormattedMessage></h4>

                    </div>

                        {duplicatePhase === 1 ?
                            <div className={'modal-content shareProduct'} style={{display:"block"}}>
                                {/*<span >Do you want to create a new version of this product?</span>*/}

                                <span className={"infoText"}><FormattedMessage {...messages.products_modal_duplicate_description}></FormattedMessage></span>

                                {productsInsideShrinkAvailableInnerVersion.length > 0 && productsInsideShrinkAvailableInnerVersion !== undefined ? (
                                        <>

                                            {Object.keys(productsInsideShrinkAvailableInnerVersion).map(key => {

                                                return (
                                                    <Product
                                                        onlyBackground={'false'}
                                                        productType={'GRID'}
                                                        productInsideShrink={true}
                                                        productInsideShrinkVersion={true}
                                                        threeID={'threeGrid'}
                                                        key={'Product' + key}
                                                        withThumbnail={true}
                                                        productThumbnail={productsInsideShrinkAvailableInnerVersion[key].thumbnail.fallback}
                                                        lastUpdatedTime={new Date(productsInsideShrinkAvailableInnerVersion[key].last_update.datetime).toLocaleString()}
                                                        productShrinkSelected={versionProductShrinkUUID === productsInsideShrinkAvailableInnerVersion[key].uuid ? true : false}
                                                        productID={productsInsideShrinkAvailableInnerVersion[key].uuid}
                                                        //productSelected={productsInsideShrinkAvailableInnerVersion[key].productSelected}
                                                        productName={productsInsideShrinkAvailableInnerVersion[key].name}
                                                        onClickChooseShrinkFunction={onClickChooseShrinkFunction}
                                                        classes={`showProduct`}
                                                        productBrand={'brand'}
                                                        productClient={'client'}
                                                        productEdition={'edition'}
                                                        productVersions={'unset'}

                                                        /*  productVolume={productsInsideShrinkAvailableInnerVersion[key].package.volume}*/
                                                        //     productPackageGroup={productsInsideShrinkAvailableInnerVersion[key].package.groups[0].name}
                                                        //   productPackageLastUUID={productsInsideShrinkAvailableInnerVersion[key].versions[productsInsideShrinkAvailableInnerVersion[key].versions.length-1].uuid}
                                                    />

                                                );
                                            })}
                                        </>
                                    ) : // <h2>Nemáš žádné produkty, začni přidáním nového</h2>
                                    null}


                                <div className={"modal-buttons"}>
                                    <GlobalButtonFinal className={"borderBlue btn-circle"} onClick={() => changeDuplicateShow(false)} style={{width:"fit-content"}}>
                                        <FormattedMessage {...messages.products_modal_duplicate_button_cancel}></FormattedMessage>
                                    </GlobalButtonFinal>
                                    <GlobalButtonFinal   className={versionProductShrinkUUID ? "borderBlue btn-circle" : "borderGray btn-circle"}  onClick={versionProductShrinkUUID ? () => duplicateProduct() : null} style={{width:"fit-content"}}>
                                        <FontAwesomeIcon   icon={faClone}/>  <FormattedMessage {...messages.products_modal_duplicate_button_duplicate}></FormattedMessage>
                                    </GlobalButtonFinal>


                                </div>

                            </div>
                        :null
                        }

                        {duplicatePhase === 2 ?
                            <div className={'modal-content shareProduct'} style={{display:"block"}}>
                                {/*<span >Do you want to create a new version of this product?</span>*/}

                                <span className={"infoText"}> <FontAwesomeIcon   icon={faClone}/>  <FormattedMessage {...messages.products_modal_duplicate_input_description}></FormattedMessage></span>

                                <input type={"text"} value={newProductName} onChange={handleNewName} style={{width:"70%", textAlign:"center", marginTop:"15px"}} />

                                <div className={"modal-buttons"} >

                                    <GlobalButtonFinal   className={"borderBlue btn-circle"} onClick={() => changeDuplicateShow(false)} style={{width:"fit-content"}}>
                                        <FormattedMessage {...messages.products_modal_duplicate_button_cancel}></FormattedMessage>
                                    </GlobalButtonFinal>

                                    <GlobalButtonFinal   className={"backgroundBlue btn-circle "}  onClick={() => duplicateProductAPI()} style={{width:"fit-content"}}>
                                        <FontAwesomeIcon   icon={faClone}/>     <FormattedMessage {...messages.products_modal_duplicate_button_duplicate}></FormattedMessage>
                                    </GlobalButtonFinal>


                                </div>

                            </div>
                            : null
                        }

                        {duplicatePhase === 3 ?
                            <div className={'modal-content shareProduct'} style={{display:"block"}}>
                                {/*<span >Do you want to create a new version of this product?</span>*/}

                                <span className={"infoText"}> <FormattedMessage {...messages.products_modal_duplicate_success_text}></FormattedMessage></span>

                                <span className={"infoText"}><b>{newProductName}</b></span>



                                <div className={"modal-buttons"} style={{width:"fit-content"}}>


                                    <GlobalButtonFinal   style={{width:"fit-content"}} className={"borderBlue btn-circle"} onClick={() => changeDuplicateShow(false)}>
                                        <FormattedMessage {...messages.products_modal_duplicate_button_close}></FormattedMessage>
                                    </GlobalButtonFinal>

                                    <Link href={"/editor/"+newDuplicateUUIDLink}>
                                        <GlobalButtonFinal
                                            style={{float:"left",width:"fit-content"}}
                                                            className={"backgroundBlue btn-circle "}
                                                            onClick={() => duplicateProductAPI()}>
                                            <FontAwesomeIcon   icon={faClone}/>    <FormattedMessage {...messages.products_modal_duplicate_button_edit}></FormattedMessage>
                                        </GlobalButtonFinal>
                                    </Link>

                                </div>

                            </div>
                            : null
                        }




                    </div>
                </ModalWindow>



            </ModalsWrapper>





        </>
    )
}